export const simplifyQuestions = (questions) => {
  return questions.map(question => {
    return {
      rank: question.rank,
      answer: question.answer,
      rankFeedback: question.rankFeedback,
      id: question.id
    };
  });
}


export const handleGenerate = async (currentQuestions, setIsGenerating, setModalMessage, setProgress, setTimeRemaining) => {
  setIsGenerating(true);
  setModalMessage({ text: 'Generating codebase', color: 'lime' });
  setProgress(0);
  setTimeRemaining(600);

  // Start the progress bar animation
  const interval = setInterval(() => {
    setProgress(prev => {
      if (prev < 100) {
        return prev + .0167; // 100 / 60 seconds
      } else {
        clearInterval(interval);
        setIsGenerating(false);
        setModalMessage({ text: `Codebase complete!`, color: 'lime' });
        return 100;
      }
    });
    setTimeRemaining(prev => prev - 1);
  }, 100);

  try {
    const response = await fetch(window.location.origin === 'http://localhost:3000' ? 'http://localhost:42069/api/run_nexus_generate' : 'https://runitbyq.com/api/run_nexus_generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ manifest: simplifyQuestions(currentQuestions) })
    });

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`);
    }
  } catch (error) {
    setModalMessage({ text: 'Error generating codebase!', color: 'red' });
    setIsGenerating(false);
    clearInterval(interval);
    console.error('Error:', error); // Log the error for debugging
  }

};

export const isLastQuestion = (currentQuestions) => {

  const unansweredQuestions = currentQuestions.filter(q => q.status !== 1);
  return unansweredQuestions.length === 0;
};


export const removeSentencesWithNumbers = (inputString) => {
  // Split the string into words
  inputString = inputString.toLowerCase()
  inputString = inputString.replace('i would give this answer a', '');
  inputString = inputString.replace('out of', '');
  inputString = inputString.replace('i would rank this', '');
  inputString = inputString.replace('i would give this response a', '');
  inputString = inputString.replace('as for the other questions,', '');
  inputString = inputString.replace('i would rank the original answer a', '');
  inputString = inputString.replace('would give this a,', '');
  inputString = inputString.replace('chatgpt,', '[Q]');
  inputString = inputString.replace('chat gpt,', '[Q]');
  inputString = inputString.replace('chat-gpt,', '[Q]');
  inputString = inputString.replace('answer a,', '');
  inputString = inputString.replace('original answer,', 'answer');
  inputString = inputString.replace('ooverall,', '');
  let words = inputString.split(/\s+/);

  // Filter out words that contain numbers between "1" and "10"
  let filteredWords = words.filter(word => {
    for (let i = 1; i <= 10; i++) {
      if (word.includes(i.toString())) {
        return false;
      }
    }
    return true;
  });

  // Join the filtered words back into a single string and split into sentences
  let newSentences = filteredWords.join(' ').match(/[^.!?]*[.!?]/g) || [];

  // Join the new sentences back into a single string
  return newSentences.join(' ');
}


export const convertType = (answer, type) => {
  if (type === 'string') {
    return answer;
  }
  if (typeof answer !== 'string') {
    return answer;
  }

  const lowerAnswer = answer.toLowerCase();
  const types = type.split(',');

  for (const singleType of types) {
    if (singleType === 'binary') {
      if (['yes', 'true', '1'].includes(lowerAnswer)) return 'yes';
      if (['no', 'false', '0'].includes(lowerAnswer)) return 'no';
    } else if (singleType === 'json') {
      try {
        return JSON.parse(answer);
      } catch {
        // Continue to the next type if JSON parsing fails
      }
    } else if (singleType === 'number') {
      const number = parseInt(answer, 10);
      if (!isNaN(number)) return number;
    } else if (singleType === 'array') {
      return answer.split(',').map(item => item.trim());
    }
  }

  return answer;
};

export const addFormattedFields = (questions) => {
  return questions.map(question => {
    if (question.collection) {
      question.formatted = convertType(question.answer, question.meta.type);
    }
    if (question.subquestions) {
      question.subquestions = addFormattedFields(question.subquestions);
    }
    return question;
  });
};

export const getApiUrl = () => {
  return window.location.hostname === 'localhost'
    ? 'http://localhost:42069/api/suggestion'
    : 'https://runitbyq.com/api/suggestion';
};

export const fetchSuggestion = async (questionIndex, currentQuestions, setCurrentQuestions, setDisplayedText, setAnswer, setModalMessage, previousQuestion, setIsLoading, submitButtonRef) => {
  const currentQuestion = currentQuestions[questionIndex];

  if (currentQuestion.suggestion && !currentQuestion.suggestions) {
    setIsLoading(true);
    const button = submitButtonRef.current;

    // Create a loader div element and add it to the DOM
    const loaderDiv = document.createElement('div');
    loaderDiv.classList.add('loader');
    button.parentElement.insertBefore(loaderDiv, button);

    try {
      const response = await fetch(getApiUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: currentQuestion.question,
          conversation: currentQuestions,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const suggestion = data.suggestion;

        const updatedQuestions = currentQuestions.map((q, index) =>
          index === questionIndex ? { ...q, suggestions: suggestion.toLowerCase() } : q
        );
        setCurrentQuestions(updatedQuestions);
        localStorage.setItem('questions', JSON.stringify(updatedQuestions));

        setDisplayedText(
          `${previousQuestion?.rankFeedback ? previousQuestion.rankFeedback.toLowerCase() + ' ' : ''}<span class="question-prompt-bottom"><span class="question">${currentQuestion.question}</span><span class="description">${currentQuestion.description}</span></span>`
        );
        setAnswer(suggestion.toLowerCase());
      } else {
        throw new Error('network response was not ok');
      }
    } catch (error) {
      setModalMessage({ text: 'error fetching suggestion!', color: 'red' });
    } finally {
      // Remove the loader div element
      loaderDiv.remove();
      setIsLoading(false);
    }
  } else {
    if (currentQuestion.suggestion) {
      setDisplayedText(
        `${previousQuestion?.rankFeedback ? '<div class="feedback">[Q] feedback: ' + removeSentencesWithNumbers(previousQuestion.rankFeedback.toLowerCase()) + ' </div>' : ''}<span class="question-prompt-bottom"><span class="question">${currentQuestion.question}</span><span class="description">${currentQuestion.description}</span></span>`
      );
    } else {
      setDisplayedText(
        `${previousQuestion?.rankFeedback ? '<div class="feedback">[Q] feedback: ' + removeSentencesWithNumbers(previousQuestion.rankFeedback.toLowerCase()) + ' </div>' : ''}<span class="questions-wrapper"><span class="question">${currentQuestion.question}</span><span class="description">${currentQuestion.description}</span></span>`
      );
    }

    setAnswer(currentQuestion.suggestions || '');
  }
};

export const getAverageRankStyles = (averageRank) => {
  if (averageRank >= 5 && averageRank < 6) return { backgroundColor: 'rgba(255, 0, 0, 0.25)', color: 'white' };
  if (averageRank >= 6 && averageRank < 7) return { backgroundColor: 'rgba(255, 165, 0, 0.25)', color: 'black' };
  if (averageRank >= 7 && averageRank < 8) return { backgroundColor: 'rgba(255, 255, 0, 0.25)', color: 'black' };
  if (averageRank >= 8 && averageRank < 9) return { backgroundColor: 'rgba(0, 255, 0, 0.25)', color: 'black' };
  if (averageRank >= 9 && averageRank <= 10) return { backgroundColor: 'rgba(0, 128, 0, 0.25)', color: 'white' };
  if (averageRank < 5) return { backgroundColor: 'rgba(139, 0, 0, 0.25)', color: 'white' };
  return {};
};

export const renderFormattedItems = (questions) => {
  let formattedItems = [];
  const recursiveSearch = (questions) => {
    questions.forEach(question => {
      if (question.collection && question.formatted !== undefined) {
        formattedItems.push({ question: question.question, formatted: question.formatted });
      }
      if (question.subquestions) {
        recursiveSearch(question.subquestions);
      }
    });
  };
  recursiveSearch(questions);
  return formattedItems;
};

export const handleRefresh = async (currentQuestionIndex, currentQuestions, setCurrentQuestions, setDisplayedText, setAnswer, setModalMessage, setIsLoading, submitButtonRef) => {
  const currentQuestion = currentQuestions[currentQuestionIndex];
  if (currentQuestion.suggestion) {
    setIsLoading(true);
    const button = submitButtonRef.current;

    // Create a loader div element and add it to the DOM
    const loaderDiv = document.createElement('div');
    loaderDiv.classList.add('loader');
    button.parentElement.insertBefore(loaderDiv, button);

    try {
      const response = await fetch(getApiUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: currentQuestion.question,
          conversation: currentQuestions,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const newSuggestion = data.suggestion.toLowerCase();

        // Update the suggestion in the current questions array
        const updatedQuestions = currentQuestions.map((q, index) =>
          index === currentQuestionIndex ? { ...q, suggestions: newSuggestion } : q
        );
        setCurrentQuestions(updatedQuestions);
        localStorage.setItem('questions', JSON.stringify(updatedQuestions));

        // Set the new suggestion as the answer (this updates the textarea)
        setAnswer(newSuggestion);
      } else {
        throw new Error('network response was not ok');
      }
    } catch (error) {
      setModalMessage({ text: 'error fetching new suggestion!', color: 'red' });
    } finally {
      // Remove the loader div element
      loaderDiv.remove();
      setIsLoading(false);
    }
  }
};

export const handleClear = (questions, setCurrentQuestions, setCurrentQuestionIndex, setDisplayedText, setAnswer, setRanks, setAverageRank, setModalMessage, submitButtonRef) => {
  localStorage.removeItem('questions');
  localStorage.removeItem('ranks');
  setCurrentQuestions(questions);
  setCurrentQuestionIndex(0);
  setDisplayedText(questions[0].question);
  setAnswer('');
  setRanks([]);
  setAverageRank(10);
  setModalMessage({ text: 'questions cleared successfully!', color: 'lime' });
};
