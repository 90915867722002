import React, { useState } from 'react';

function Form({ handleGenerate }) {
  const [formData, setFormData] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleInputChange = (e) => {
    formData[e.target.id] = e.target.value
    setFormData(formData);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    handleGenerate();
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div className="form-modal">
      <form onSubmit={handleSubmit} className="form">
        <label>
          have you purchased the domain name:
          <input type="text" value={formData.nexus_domain_name} onChange={handleInputChange} />
        </label>
        <label>
          enter aws api key:
          <input type="text" value={formData.nexus_aws_api_key} onChange={handleInputChange} />
        </label>
        <label>
          NEXUS_T2_FORM_FIELD_1_LABEL:
          <input type="text" value={formData.nexus_t2_form_field_1_value} onChange={handleInputChange} />
        </label>
        <button type="button" onClick={toggleModal}>help</button>
        <button type="submit">Generate</button>
      </form>
      {isModalVisible && (
        <FormModal toggleModal={toggleModal} />
      )}
    </div>
  );
}

function FormModal({ toggleModal }) {
  return (
    <div className="form-modal-inner">
      <div className="form-modal-content">
        <span className="close" onClick={toggleModal}>[&times;]</span>
        <h2>help</h2>
        <p>
          https://domains.squarespace.com/ please register and purchase a domain name here
          https://account.squarespace.com/domains/managed/cyberdynerobotic.systems/dns/domain-nameservers click use custom nameservers
          enter gemma.ns.cloudflare.com and norman.ns.cloudflare.com and click save
        </p>
      </div>
    </div>
  );
}

export default Form;
