import React, { useEffect, useRef, useState } from 'react';
import questions from './questions.json';
import {
  convertType,
  addFormattedFields,
  getApiUrl,
  fetchSuggestion,
  getAverageRankStyles,
  renderFormattedItems,
  handleRefresh,
  handleClear,
  handleGenerate,
  simplifyQuestions,
} from './utilities';
import moment from 'moment';
import Form from './Form';

function App() {
  const textareaRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [currentQuestions, setCurrentQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem('questions');
    return savedQuestions ? JSON.parse(savedQuestions) : questions;
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState('');
  const [modalMessage, setModalMessage] = useState(null);
  const [displayedText, setDisplayedText] = useState('');
  const [ranks, setRanks] = useState([]);
  const [averageRank, setAverageRank] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [showConsole, setShowConsole] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [showMainContent, setShowMainContent] = useState(false);
  const [isFullsize, setIsFullsize] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('default');
  const [isGenerating, setIsGenerating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(600);
  const [errorMessages, setErrorMessages] = useState([
    "당신의 아이디어는 좋지 않습니다.",
    "your idea does not have high enough [Q] score you may not continue",
    "あなたはバカです",
    "Ваша идея не хороша.",
    "Deine Idee ist nicht gut.",
    "Your idea is shit m8",
    "Skibidi based, this is no rizz..",
    "Your idea is straight up cringe. It's not going to pop off.",
    "Your idea sucks dude. Its never gonna work.",
    "Your idea is whack. There's no way it's going to work.",
    "Your idea is a bunch of hogwash. It's not going to fly, kid.",
    "Your idea is pure malarkey and it aint gonna cut mustard"
  ]);
  const [currentErrorMessageIndex, setCurrentErrorMessageIndex] = useState(0);
  const NEXUS_DOMAIN_NAME = currentQuestions.find(q => q.id === 'question-8')?.answer || '';

  useEffect(() => {
    // Randomly add a class to the body tag
    const classes = ['orange', 'purple', 'blue', 'green', 'pink'];
    const randomClass = classes[Math.floor(Math.random() * classes.length)];
    document.body.classList.add(randomClass);

    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    const nextQuestionIndex = currentQuestions.findIndex(q => q.status !== 1);
    if (nextQuestionIndex !== -1) {
      setCurrentQuestionIndex(nextQuestionIndex);
      fetchSuggestion(
        nextQuestionIndex,
        currentQuestions,
        setCurrentQuestions,
        setDisplayedText,
        setAnswer,
        setModalMessage,
        getPreviousQuestion(nextQuestionIndex, currentQuestions),
        setIsLoading,
        submitButtonRef
      );
    } else {
      // This is the fix for the last question scenario
      setDisplayedText(''); // Clear displayedText if used previously
    }

    const savedRanks = JSON.parse(localStorage.getItem('ranks')) || [];
    setRanks(savedRanks);

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures this runs only once on page load

  useEffect(() => {
    if (ranks.length > 0) {
      const average = ranks.reduce((acc, rank) => acc + rank, 0) / ranks.length;
      setAverageRank(average.toFixed(2));
    }

    // Calculate completion percentage
    const totalQuestions = currentQuestions.filter(q => !q.subquestions).length;
    const completedQuestions = currentQuestions.filter(q => q.status === 1 && !q.subquestions).length;
    const percentage = (completedQuestions / totalQuestions) * 100;
    setCompletionPercentage(percentage.toFixed(2));
  }, [ranks, currentQuestions]);

  useEffect(() => {
    if (averageRank < 5) {
      const interval = setInterval(() => {
        setCurrentErrorMessageIndex(prevIndex => (prevIndex + 1) % errorMessages.length);
        setModalMessage({ text: errorMessages[(currentErrorMessageIndex + 1) % errorMessages.length], color: 'red' });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [averageRank, errorMessages.length, currentErrorMessageIndex]);

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleSubmit = async () => {
    setHideForm(true)

    if (isLoading) return;

    setIsLoading(true);
    const currentQuestion = currentQuestions[currentQuestionIndex];
    const button = submitButtonRef.current;

    button.classList.add('loading');

    // Create a loader div element and add it to the DOM
    const loaderDiv = document.createElement('div');
    loaderDiv.classList.add('loader');
    button.parentElement.appendChild(loaderDiv);

    try {
      let response, data;

      // Regular question submission
      response = await fetch('https://runitbyq.com/api/q', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          question: currentQuestion.question,
          answer: answer,
          validator: currentQuestion.validator,
          description: currentQuestion.description,
          type: currentQuestion.type,
          subquestions: currentQuestion.subquestions || [],
          conversation: currentQuestions
        })
      });

      if (response.ok) {
        data = await response.json();
        const rank = parseInt(data.rank, 10);
        const rankFeedback = data.rank_feedback;

        const updatedRanks = [...ranks, rank];
        setRanks(updatedRanks);
        localStorage.setItem('ranks', JSON.stringify(updatedRanks));

        if (updatedRanks.length > 0) {
          const average = updatedRanks.reduce((acc, rank) => acc + rank, 0) / updatedRanks.length;
          setAverageRank(average.toFixed(2));
          if (average < 5) {
            setModalMessage({ text: errorMessages[currentErrorMessageIndex], color: 'red' });
            submitButtonRef.current.disabled = true;
            textareaRef.current.disabled = true;
            return;
          }
        }

        const updatedQuestions = currentQuestions.map((q, index) =>
          index === currentQuestionIndex ? { ...q, status: 1, rank, rankFeedback, answer } : q
        );
        setCurrentQuestions(updatedQuestions);
        localStorage.setItem('questions', JSON.stringify(updatedQuestions));

        const nextQuestionIndex = updatedQuestions.findIndex(q => q.status !== 1);
        if (nextQuestionIndex !== -1) {
          setCurrentQuestionIndex(nextQuestionIndex);
          fetchSuggestion(
            nextQuestionIndex,
            updatedQuestions,
            setCurrentQuestions,
            setDisplayedText,
            setAnswer,
            setModalMessage,
            getPreviousQuestion(nextQuestionIndex, updatedQuestions),
            setIsLoading,
            submitButtonRef
          );
        } else {
          setCurrentQuestionIndex(-1);
          setShowMainContent(false);
        }
        setAnswer('');
      } else {
        throw new Error('network response was not ok');
      }
    } catch (error) {
      setModalMessage({ text: 'error!', color: 'red' });
    } finally {
      // Remove the loader div element
      loaderDiv.remove();
      button.classList.remove('loading');
      setIsLoading(false);
    }
  };

  const getPreviousQuestion = (index, questions) => {
    return questions.slice(0, index).reverse().find(q => q.status === 1);
  };

  const formattedQuestions = addFormattedFields(currentQuestions);
  const formattedItems = renderFormattedItems(formattedQuestions);

  const toggleConsole = () => {
    setShowConsole(!showConsole);
  };

  const closeConsole = () => {
    setShowConsole(false);
  };

  const handleClearContent = () => {
    handleClear(questions, setCurrentQuestions, setCurrentQuestionIndex, setDisplayedText, setAnswer, setRanks, setAverageRank, setModalMessage);
    setShowMainContent(false);
  };

  const toggleFullsize = () => {
    setIsFullsize(!isFullsize);
  };

  const handleThemeChange = (event) => {
    const theme = event.target.value;
    setSelectedTheme(theme);
    document.body.className = theme;
  };

  const isLastQuestion = (questions) => {
    return questions.every(q => q.status === 1);
  };

  return (
    <div className="page">
      <header className="header">
        <h1>nexus</h1>
      </header>
      {modalMessage && (
        <div className="modal" style={{ backgroundColor: modalMessage.color, color: 'black' }}>
          <span className="close" onClick={() => setModalMessage(null)}>[&times;]</span>
          {modalMessage.text}
        </div>
      )}
      <img className="background" src="/nexus_console.png" alt="Nexus Console" />
      <main className={`fade-in ${showMainContent ? 'visible' : 'hidden'}`}>

        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${completionPercentage}%` }}></div>
        </div>
        <div className="actions-top">
          <select onChange={handleThemeChange} value={selectedTheme} className="theme-selector">
            <option value="default">Theme</option>
            <option value="light-theme">Light</option>
            <option value="dark-theme">Dark</option>
            <option value="blue-theme">Blue</option>
            <option value="green-theme">Green</option>
            <option value="red-theme">Red</option>
            <option value="matrix-theme">Matrix</option>
          </select>

          {ranks.length > 0 && (
            <button className="score" style={getAverageRankStyles(averageRank)} disabled>
              [<strong>Q</strong>] Score: {averageRank} | Progress: {completionPercentage}%
            </button>
          )}

          {currentQuestions[currentQuestionIndex]?.suggestion && (
            <button className="refresh" onClick={() => handleRefresh(currentQuestionIndex, currentQuestions, setCurrentQuestions, setDisplayedText, setAnswer, setModalMessage, setIsLoading, submitButtonRef)} disabled={isLoading}>autofill</button>
          )}

          <button className="clear" onClick={handleClearContent} disabled={isLoading}>[&times;] Start Over</button>
        </div>
        {isGenerating ? (
          <div className="progress-wrapper">
            <span className="progress-text">{progress.toFixed(2)}% complete</span>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: `${progress}%` }}> </div>
            </div>
          </div>
        ) : (
          <>
            {isLastQuestion(currentQuestions) && !hideForm ? (
              <Form handleGenerate={handleGenerate(currentQuestions, setIsGenerating, setModalMessage, setProgress, setTimeRemaining)} />
            ) : (
              <div className={`chat-window ${currentQuestions[currentQuestionIndex]?.suggestion ? 'has-suggestion' : ''} ${currentQuestions[currentQuestionIndex]?.description ? 'has-description' : ''}`} dangerouslySetInnerHTML={{ __html: displayedText }}></div>
            )}
          </>
        )}

        {
          showConsole && (
            <div className="console">
              <button className="close-console fade-in" onClick={closeConsole}>[&times;]</button>
              <div className="formatted-questions">
                <h2>[Q] code</h2>
                <pre>{JSON.stringify(formattedQuestions, null, 2)}</pre>
              </div>
              <div className="formatted-items">
                <h2>final manifest</h2>
                <pre>{JSON.stringify(formattedItems, null, 2)}</pre>
              </div>
            </div>
          )
        }
        <button className={isFullsize ? 'fullsize-toggle action-minimize' : 'fullsize-toggle action-fullsize'} onClick={toggleFullsize}>{isFullsize ? 'minimize' : 'fullsize'}</button>
        <textarea
          ref={textareaRef}
          value={answer}
          onChange={handleInputChange}
          placeholder="enter your answer"
          className={`fade-in ${isFullsize ? 'fullsize' : ''}`}
          disabled={isLoading}
        ></textarea>
        <div className="actions-bottom">
          <button className="submit" onClick={handleSubmit} ref={submitButtonRef} disabled={isLoading}>Enter</button>
          <button className="console-button" onClick={toggleConsole}>Console</button>
        </div>

        <article>
          <section>
          </section>
        </article>


      </main >
      <div className={`logo  logo-animated ${showMainContent ? 'hidden' : 'visible'}`} onClick={() => setShowMainContent(true)}>
        <h2 className="">[Q]</h2>
        <h2 className="">[Q]</h2>
        <h2 class="home-title-subtext">got an idea? run it by [Q]!</h2>
        <h2 class="home-title-subtext">got an idea? run it by [Q]!</h2>
      </div>
      <footer className="footer">run it by q &copy; 2024 [nexus platform]</footer>
    </div >
  );
}

export default App;
